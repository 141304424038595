import { Injectable, inject, signal } from '@angular/core';
import {
  Account,
  AccountRef,
  DistrictInfo,
  GeoLocation,
  GetAccountsOfPotentialDrivers,
  GetAccountsOfPotentialDriversAsAdmin,
  Team,
  UpdateUserInfo
} from '@moxi.gmbh/moxi-typescriptmodels';
import { sendCommand, sendQuery } from '@shared/data/flux';
import { Observable, finalize, map, tap } from 'rxjs';
import { AppStore } from './store/app.store';

/**
 * //TODO: Refactor this service
 * - Identify the dependencies of this service
 * - Identify the methods of this service
 * - Move methods to the appropriate services in other domains
 */
@Injectable({ providedIn: 'root' })
export class CommonService {
  private readonly appStore = inject(AppStore);

  private readonly loading = signal(false);

  readonly isLoading = this.loading.asReadonly();

  getDistrict(geoLocation: GeoLocation): Observable<DistrictInfo> {
    this.loading.set(true);

    return sendQuery<DistrictInfo>(`com.moxi.api.geojson.GetDistrict`, {
      geoLocation: geoLocation
    }).pipe(finalize(() => this.loading.set(false)));
  }

  /**
   * Update user profile language (used for in the language change event in the header component)
   */
  updateProfileLanguage(language: string): Observable<void> {
    const userProfile = this.appStore.userProfile();
    const payload: UpdateUserInfo = {
      userId: userProfile.userId,
      info: { ...userProfile.info, language }
    };

    return sendCommand<void>('com.moxi.api.user.UpdateUserInfo', {
      ...payload
    }).pipe(tap(() => this.appStore.setUserProfile({ info: payload.info })));
  }

  getAccountsOfPotentialDrivers(
    payload: GetAccountsOfPotentialDrivers
  ): Observable<AccountRef[]> {
    this.loading.set(true);

    return sendQuery<AccountRef[]>(
      `com.moxi.api.account.GetAccountsOfPotentialDrivers`,
      {
        ...payload
      }
    ).pipe(finalize(() => this.loading.set(false)));
  }

  getAccountsOfPotentialDriversAsAdmin(
    payload: GetAccountsOfPotentialDriversAsAdmin
  ): Observable<Account[]> {
    this.loading.set(true);

    return sendQuery<Account[]>(
      `com.moxi.api.account.GetAccountsOfPotentialDriversAsAdmin`,
      {
        ...payload
      }
    ).pipe(finalize(() => this.loading.set(false)));
  }

  getAccountTeams(accountId: string): Observable<Team[]> {
    this.loading.set(true);

    return sendQuery<Account>('com.moxi.api.account.GetAccount', {
      accountId
    }).pipe(
      map(account => account?.teams ?? []),
      finalize(() => this.loading.set(false))
    );
  }

  encryptValue(value: string): Observable<string> {
    this.loading.set(true);

    return sendCommand<string>('com.moxi.api.admin.EncryptValue', {
      value
    }).pipe(finalize(() => this.loading.set(false)));
  }

  checkIfUserSignedUp(userId: string): Observable<boolean> {
    this.loading.set(true);

    return sendQuery<boolean>('com.moxi.api.user.CheckIfUserSignedUp', {
      userId
    }).pipe(finalize(() => this.loading.set(false)));
  }

  getGoogleMapsUiKey(): Observable<string> {
    this.loading.set(true);

    return sendQuery<string>(
      'com.moxi.api.refdata.GetGoogleMapsUiKey',
      {},
      {
        responseType: 'text'
      }
    ).pipe(finalize(() => this.loading.set(false)));
  }
}
